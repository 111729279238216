import * as React from "react"
import { Link } from "gatsby"

const Navigation = ({ title }) => (
  <nav className="bg-gray-800">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between h-16">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <Link
              to="/"
              className="font-mono text-white text-2xl hover:text-gray-400"
            >
              {title}
            </Link>
          </div>
        </div>
      </div>
    </div>
  </nav>
)

export default Navigation
