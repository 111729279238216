/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = ({ className }) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  // const social = data.site.siteMetadata?.social

  return (
    <div className={className}>
      <StaticImage
        className="object-cover rounded-full mx-4"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/profile-pic.png"
        width={80}
        height={80}
        quality={95}
        alt="Profile picture"
      />
      <p className="text-gray-700 text-center font-bold mx-1">{author.name}</p>
      <p className="text-gray-700 text-sm font-light mt-4">
        {author?.summary || null}
      </p>
    </div>
  )
}

export default Bio
